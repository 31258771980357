import { Container } from '@material-ui/core';
import React from 'react';
import styled from '@emotion/styled';
import TechBox from './techBox';
import BackendIcon from '../../assets/svg/aplikacje-web.svg';
import MobileIcon from '../../assets/svg/aplikacje-mobilne.svg';
import WebsitesIcon from '../../assets/svg/strony-internetowe.svg';
import PWAIcon from '../../assets/svg/publikacja-strony-internetowej.svg';
import CrmErpIcon from '../../assets/svg/crm-erp.svg';
import ConsultingIcon from '../../assets/svg/consult.svg';
import DevOpsIcon from '../../assets/svg/devops.svg';
import theme from '../theme/theme';

const Sh3 = styled.h3`
    text-align: left;
    font-weight: 600;
    span {
        color: ${theme.primary}
    }
`;

const AllServices = () => (
  <Container style={{ marginBottom: '90px' }}>
    <Sh3 style={{ textAlign: 'left', fontWeight: 600 }}>
      Wszystkie usługi
      {' '}
      <span>iqcode</span>
    </Sh3>
    <TechBox
      fullWidth
      technologies={[
        {
          icon: <MobileIcon />,
          name: 'Aplikacje mobilne',
          link: '/aplikacje-mobilne',
        },
        {
          icon: <WebsitesIcon />,
          name: 'Strony i serwisy internetowe',
          link: '/strony-internetowe',
        },
        {
          icon: <PWAIcon />,
          name: 'Aplikacje progresywne',
          link: '/aplikacje-progresywne-pwa',
        },
        {
          icon: <BackendIcon />,
          name: 'Aplikacje serwerowe',
          link: '/aplikacje-serwerowe',
        },
        {
          icon: <CrmErpIcon />,
          name: 'Systemy CRM/ERP',
          link: '/systemy-crm-erp',
        },
        {
          icon: <DevOpsIcon />,
          name: 'Usługi DevOps',
          link: '/devops',
        },
        {
          icon: <ConsultingIcon />,
          name: 'Consulting IT',
          link: '/consulting-it',
        },
      ]}
    />
  </Container>
);

export default AllServices;
